import React from "react";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import locationPinSmall from "@ingka/ssr-icon/paths/location-pin-small";
import { useTranslation } from "@coworker/locales";
import { Text } from "../Atoms/Text";

interface HFBLocationProps {
  hfb: string;
}

export const HFBLocation = ({ hfb }: HFBLocationProps) => {
  const { t } = useTranslation();

  const addOnContent = hfb ? <Text text={hfb} grey /> : "";

  return (
    <FixaListViewItem
      title={t("hfbLocationInStoreString")}
      leadingIcon={locationPinSmall}
      addOn={addOnContent}
      emphasised
      inset
    />
  );
};
