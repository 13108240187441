import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { useTranslation } from "@coworker/locales";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import taskState from "@coworker/functions/src/enums/taskState.json";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import useFormatter from "../../hooks/useFormatter";
import { useFeatureFlagHooks } from "../MFAQ/useMFAQs.featureToggle";
import { defaultFilters } from "./UserFiltersRow/filterChoices";
import { Icon } from "@coworker/components";
import { usePeriodTimestamps } from "../InputPopup/UserFiltersRow/usePeriodTimestamps";
import tracker from "../../helpers/tracker";
import { isFinishedState } from "../../constants/taskState";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import CloseIcon from "@ingka/ssr-icon/paths/cross";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import "@coworker/apprestructured/src/activity/components/filters/panel/filter-list-view.css";
const { MFAQ_PREFIX, USER_FILTERS } = profilePreferences;

const MessageWrapper = styled.div`
  margin: 40px;
  display: inline-block;
`;

const MessageText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: var(--error);
`;
const SearchWrapper = styled.div`
  padding: 12px;
  border-bottom: 1px solid var(--grey200);
`;

export function MFAQmergeQuestion(props) {
  const { searchMFAQ, useMFAQ } = useFeatureFlagHooks();
  const store_id = useStoreId();
  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );

  const { t } = useTranslation();
  const { toTimeAgo, formatNumberShort } = useFormatter();
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const firstQuestionData = useMFAQ(props.questionId, startDate, endDate);
  const [foundQuestions, setFoundQuestions] = React.useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = React.useState(null);

  // Remove the base question from search results
  const filteredQuestions = React.useMemo(
    () =>
      foundQuestions?.filter(
        (question) => question.question_id !== props.questionId
      ),
    [foundQuestions, props.questionId]
  );

  const selectedQuestionExist = React.useMemo(
    () => filteredQuestions.some((q) => q.question_id === selectedQuestionId),
    [filteredQuestions, selectedQuestionId]
  );

  const selectedQuestionOngoingTasks = React.useMemo(() => {
    const list = [];
    for (const q of filteredQuestions) {
      if (q.question_id !== selectedQuestionId) continue;
      for (const task of q?.tasks || []) {
        if (!isFinishedState(task?.state)) list.push(task);
      }
    }
    return list;
  }, [filteredQuestions, selectedQuestionId]);

  // Currently, merge not allowed if both questions have ongoing tasks in any area.
  const questionHasTask =
    !!selectedQuestionOngoingTasks.length &&
    firstQuestionData?.tasks?.some(
      (task) => ![taskState.COMPLETED, taskState.CLOSED].includes(task?.state)
    );

  React.useEffect(() => {
    // Unselect previously selected question
    if (selectedQuestionId && !selectedQuestionExist) {
      setSelectedQuestionId(null);
    }
  }, [selectedQuestionId, selectedQuestionExist]);

  // We want to track if both questions have tasks.
  if (questionHasTask) {
    tracker.MFAQ.trackMergeNotPossible(props.questionId, selectedQuestionId);
  }

  const appBarActions = [
    {
      name: "close",
      icon: <SSRIcon paths={CloseIcon} />,
      onClick: props.onClose,
      position: "right",
    },
  ];
  return (
    <>
      <FullScreenPopup
        noPadding
        appBarConfig={{
          title: t("mfaqMergeQuestionString"),
          backButton: false,
          actions: appBarActions,
        }}
        actionBarContent={
          <Button
            data-testid="MFAQmergeQuestion"
            type="primary"
            disabled={!selectedQuestionId || questionHasTask}
            text={t("mfaqMergeString")}
            onClick={(event) => {
              event.preventDefault();
              tracker.MFAQ.trackMergeButton(
                props.questionId,
                selectedQuestionId
              );
              props.onSubmit(selectedQuestionId);
            }}
          />
        }
      >
        <SearchWrapper>
          <FixaSearch
            id="search"
            onChange={async (e) => {
              const found = await searchMFAQ(
                e.target.value,
                null,
                filters[1],
                store_id
              );
              setFoundQuestions(found);
            }}
            placeholder={t("searchQuestionString")}
            onClear={() => {
              setFoundQuestions([]);
            }}
          />
        </SearchWrapper>
        <FixaListView id={"mergeQuetions"} className="filters-panel">
          {filteredQuestions.map((question) => {
            const date = toTimeAgo(question?.last_asked_at);
            return (
              <FixaListViewItem
                key={question.id}
                id={question.id}
                control={"radiobutton"}
                onChange={() => {
                  setSelectedQuestionId(question.question_id);
                  console.log("selectedQuestionId", question.question_id);
                }}
                title={question.question_text}
                quantityLabel={formatNumberShort(question.upvotes)}
                description={t("lastAskedAtString", { date })}
                size="small"
                emphasised
              />
            );
          })}
        </FixaListView>
        {questionHasTask && (
          <MessageWrapper>
            <Icon
              name="warning_triangle"
              size="16"
              family="icons"
              color="error"
            />
            <MessageText>
              <Trans>mergeNotPossibleMessageString</Trans>
            </MessageText>
          </MessageWrapper>
        )}
      </FullScreenPopup>
    </>
  );
}
