import React from "react";
import Modal, { ModalHeader, ModalFooter, Sheets } from "@ingka/modal";
import { Trans, useTranslation } from "@coworker/locales";
import { Article } from "../../types/article";
import { ModalTypes } from "../../types/views";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import {
  LeftContainer,
  ListItemContainer,
  RightContainer,
  SizedText,
  TextStyle,
} from "../../styles/styles";
import { Groups } from "../../types/groups";

import SSRIcon from "@ingka/ssr-icon"; // NOSONAR
import trashCan from "@ingka/ssr-icon/paths/trash-can"; // NOSONAR
import { logEvent } from "../../../../helpers/tracker";

interface SelectedArticlesActionsModalProps {
  selectedArticles: Article[];
  modalVisibility: boolean;
  onTriggerNewModal: (modalType: ModalTypes) => void;
  groups: Groups;
}

export const SelectedArticlesActionsModal = ({
  selectedArticles,
  modalVisibility,
  onTriggerNewModal,
  groups,
}: SelectedArticlesActionsModalProps) => {
  const { t } = useTranslation();

  const logEventForModalType = (modalType: ModalTypes) => {
    switch (modalType) {
      case ModalTypes.PTAG_ARTICLE_NUMBERS:
        logEvent("ce:articles_selected_copyAllArticleNumbers");
        break;
      case ModalTypes.CREATE_GROUP:
        logEvent("ce:articles_selected_creategroup");
        break;
      case ModalTypes.ADD_TO_GROUP:
        logEvent("ce:articles_selected_addtogroup");
        break;
      case ModalTypes.DELETE_SELECTED_ARTICLES:
        logEvent("ce:articles_selected_deletearticles");
        break;
      default:
    }
  };

  const actionMenuChoice = (
    text: string,
    modalType: ModalTypes,
    disabled: boolean
  ) => {
    if (modalType === ModalTypes.ADD_TO_GROUP && (groups?.length ?? 0) === 0) {
      return null;
    }
    return (
      <FixaListViewItem
        onClick={() => {
          if (!disabled) {
            logEventForModalType(modalType);
            onTriggerNewModal(modalType);
          }
        }}
        title={
          <SizedText
            style={{ margin: "0px 24px" }}
            $fontSize={16}
            $bold={!disabled}
            onClick={() => {
              logEventForModalType(modalType);
              onTriggerNewModal(modalType);
            }}
          >
            <Trans>{text}</Trans>
          </SizedText>
        }
        emphasised
        size="small"
      />
    );
  };

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.KEEP_SELECTION);
      }}
    >
      <Sheets
        labelledById="selectedArticlesActions"
        size={"small"}
        header={
          <ModalHeader
            ariaCloseTxt="Close"
            title={`${selectedArticles?.length ?? 0} ${t(
              "articlesSelectedString"
            )}`}
          />
        }
        footer={
          <ModalFooter>
            <></>
          </ModalFooter>
        }
      >
        <FixaListView id="selectedArticlesActionsId" size="small">
          {actionMenuChoice(
            "createPTagPrintListString",
            ModalTypes.PTAG_ARTICLE_NUMBERS,
            false
          )}
          {actionMenuChoice(
            "createGroupString",
            ModalTypes.CREATE_GROUP,
            false
          )}
          {actionMenuChoice(
            "addToGroupString",
            ModalTypes.ADD_TO_GROUP,
            (groups?.length ?? 0) === 0
          )}
          <ListItemContainer
            onClick={() => {
              logEventForModalType(ModalTypes.DELETE_SELECTED_ARTICLES);
              onTriggerNewModal(ModalTypes.DELETE_SELECTED_ARTICLES);
            }}
          >
            <LeftContainer>
              <div style={{ justifyContent: "left" }}>
                <TextStyle
                  $bold={true}
                  $italic={false}
                  $size={"medium"}
                  $color="pink"
                >
                  <Trans>deleteSelectedArticlesString</Trans>
                </TextStyle>
              </div>
            </LeftContainer>
            <RightContainer>
              <SSRIcon paths={trashCan} color="var(--pink)" />
            </RightContainer>
          </ListItemContainer>
        </FixaListView>
      </Sheets>
    </Modal>
  );
};
