import React from "react";
import {
  clean,
  makeMatcher,
} from "@coworker/components/src/helpers/string.helpers";
import { useTranslation } from "@coworker/locales";
import { logEvent } from "../helpers/tracker";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { useUserAvatarQuery } from "../hooks/API/avatar.service";
import styles from "./AssignToUserOrGroup.module.css";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";

function sortByName(
  { name: oneName = "" } = {},
  { name: anotherName = "" } = {}
) {
  return (
    oneName
      ?.toLocaleLowerCase()
      ?.localeCompare(anotherName?.toLocaleLowerCase()) || 0
  );
}

export function AssignToUserOrGroup({
  assignees,
  users,
  assignableTeams,
  me,
  onSubmit,
  onKeepTaskClick,
  handoff,
  flow,
  assignToMyTeamOpened,
  setAssignToMyTeamOpened,
  disableTeamAssignment = false,
  spacer = true,
  searchQuery = "",
  suggestions,
  isPqr = false,
  overflow = true,
}) {
  if (isPqr) {
    assignableTeams = assignableTeams.filter(
      (team) => team.is_product_quality === true
    );
  }

  const matchAll = makeMatcher(searchQuery, true);
  const matcher = (string) => !matchAll || matchAll.test(clean(string));
  const { uid, gid } = assignees || {};
  const checkIfSelected = (id) => (uid ? id === uid : gid && id === gid);

  const { t } = useTranslation();
  const myTeam = assignableTeams?.find((team) => team.id === me.group);
  const myTeamName = myTeam?.name || t("myTeamString");

  const [openedTeamIds, setOpenedTeamIds] = React.useState(() => {
    const teamId = myTeam?.id;
    return teamId ? [teamId] : [];
  });

  function membersForTeam(users, gid, matcher) {
    const chosen = users.filter(
      (user) => user.team_id === gid && matcher(user.name)
    );
    chosen.sort(sortByName);
    return chosen;
  }

  const doSubmit = React.useCallback(
    (item) => {
      onSubmit({
        name: item.name,
        uid: item.uid || "",
        gid: item.gid || item.team_id,
      });
    },
    [onSubmit]
  );

  const teams = assignableTeams.sort((teamA, teamB) => {
    if (teamA.id === me.group) return -1;
    if (teamB.id === me.group) return 1;

    return sortByName(teamA, teamB);
  });

  function handleAccordionClick(id) {
    const isOpen = openedTeamIds.includes(id);
    if (isOpen) {
      setOpenedTeamIds(openedTeamIds.filter((item) => item !== id));
    } else {
      setOpenedTeamIds([...openedTeamIds, id]);
    }
  }

  return (
    <div
      data-testid="assignBackground"
      spacer={spacer}
      overflow={overflow}
      className={`${styles["container"]}
        ${spacer && styles["container-spacer"]}
        ${overflow && styles["container-overflow"]}
      `}
    >
      {handoff && (
        <>
          <FixaListView id="keep-task">
            <FixaListViewItem
              className={styles["keep-task"]}
              inset
              title={t("keepString")}
              control="radiobutton"
              controlProps={{
                defaultChecked: checkIfSelected(me.id),
                onClick: () => {
                  if (typeof onKeepTaskClick === "function") {
                    onKeepTaskClick();
                  }
                  doSubmit({
                    name: t("meString"),
                    uid: me.id,
                    gid: me.group,
                  });
                  logEvent("ce:assign_to", {
                    type: "coworker",
                    coworker: "me",
                  });
                },
              }}
            />
          </FixaListView>
        </>
      )}
      {suggestions?.length > 0 && (
        <FixaListView
          id={"suggestions"}
          className={styles["suggestions"]}
          size="small"
        >
          <FixaListViewItem
            title={t("assignedRecentlyString")}
            emphasised
            inset
          />
          {suggestions.map(([gid, [text, subtext]], i) => (
            <FixaListViewItem
              className={
                i === suggestions.length - 1 && styles["last-suggestion"]
              }
              key={gid}
              id={gid}
              title={
                <>
                  <div>{text}</div>
                  {t("mostRecentlyString")} {subtext}
                </>
              }
              inset
              control="radiobutton"
              controlProps={{
                defaultChecked: checkIfSelected(gid),
                onClick: () => {
                  doSubmit({ gid });
                  logEvent("ce:assign_to", { type: "suggested", team: gid });
                },
              }}
            />
          ))}
        </FixaListView>
      )}
      {teams.map((team, i) => {
        const teamMembers = membersForTeam(users, team.id, matcher);
        const matchTeamName = matcher(team.name);
        const isMyTeam = team.name === myTeamName;
        return (
          (teamMembers.length > 0 || matchTeamName) && (
            <Accordion
              className={styles["accordion"]}
              size="medium"
              key={team.id}
            >
              <AccordionItem
                className={styles["accordion-item"]}
                id={team.id}
                title={team.name}
                open={
                  openedTeamIds.includes(team.id) ||
                  (searchQuery !== "" && matcher(searchQuery))
                }
                onClick={() => handleAccordionClick(team.id)}
                caption={isMyTeam && t("myCurrentTeamString")}
              >
                <FixaListView id={team.id} size="small">
                  {matchTeamName && (
                    <Team
                      key={team.id}
                      team={team}
                      index={i}
                      isMyTeam={isMyTeam}
                      checkIfSelected={checkIfSelected}
                      doSubmit={doSubmit}
                      logEvent={logEvent}
                    />
                  )}
                  {teamMembers.map(
                    (item, u) =>
                      matcher(item.name) && (
                        <Member
                          index={u}
                          key={item.fixa_uid}
                          member={item}
                          team={team}
                          checkIfSelected={checkIfSelected}
                          doSubmit={doSubmit}
                          logEvent={logEvent}
                          matcher={matcher}
                        />
                      )
                  )}
                </FixaListView>
              </AccordionItem>
            </Accordion>
          )
        );
      })}
    </div>
  );
}

function Team({ team, isMyTeam, checkIfSelected, doSubmit, logEvent, index }) {
  return (
    <FixaListViewItem
      key={team.id}
      id={team.id}
      className={styles["team"]}
      title={team.name}
      control="radiobutton"
      inset
      controlProps={{
        defaultChecked: checkIfSelected(team.id),
        onClick: () => {
          doSubmit({
            name: team.name,
            gid: team.id,
            uid: "",
          });
          logEvent("ce:assign_to", {
            type: "team",
            team: isMyTeam ? "mine" : team.id,
          });
        },
      }}
    />
  );
}
function Member({
  member,
  team,
  checkIfSelected,
  doSubmit,
  logEvent,
  matcher,
  index,
}) {
  const avatarImage = useUserAvatarQuery(member.fixa_uid);
  const avatarUrl = avatarImage && URL.createObjectURL(avatarImage);
  return (
    <FixaListViewItem
      key={member.uid}
      className={styles["member"]}
      id={member.fixa_uid}
      title={member.name}
      image={avatarUrl}
      control="radiobutton"
      inset
      controlProps={{
        defaultChecked: checkIfSelected(member.fixa_uid),
        onClick: () => {
          doSubmit({
            name: team.name,
            gid: team.id,
            uid: member.fixa_uid,
          });
          logEvent("ce:assign_to", {
            type: "coworker",
            coworker: member.fixa_uid,
          });
        },
      }}
    />
  );
}
