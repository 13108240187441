import React from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/focus/dist/style.css";
import { Trans } from "@coworker/locales";
import { ShoppingToolType } from "../constants";
import { SettingsDefault, SettingsResponse } from "../Types";
import styled, { AnyStyledComponent } from "styled-components";

interface AdditionalSettings {
  [key: string]: boolean;
}

export type SettingsKeyType = SettingsDefault & AdditionalSettings;

interface ShoppingToolsListViewProps {
  id: string;
  itemList: ShoppingToolType[];
  setSettingsList: React.Dispatch<
    React.SetStateAction<SettingsDefault[] | SettingsResponse[]>
  >;
  settingsList: SettingsDefault[] | SettingsResponse[];
  divisionSettings: SettingsDefault | SettingsResponse;
}

const StyledListViewItem = styled(FixaListViewItem as AnyStyledComponent)`
  padding: 0 1rem;
  &:last-child {
    border-bottom: none;
  }
`;

export const SettingsListView = ({
  id,
  itemList,
  setSettingsList,
  settingsList,
  divisionSettings,
}: ShoppingToolsListViewProps) => {
  function getValueByName(name: string) {
    return (divisionSettings as SettingsKeyType)[name];
  }

  const updatedItemList = itemList.filter((item) => item.name !== "no_tool");

  return (
    <FixaListView id={id} size="small">
      {updatedItemList.map((item) => {
        return (
          <StyledListViewItem
            key={item.label}
            control={item.control}
            controlProps={{
              checked: (divisionSettings && getValueByName(item.name)) ?? false,
              value: item.name,
            }}
            title={<Trans>{item.label}</Trans>}
            leadingIcon={item.icon}
            onChange={() => {
              const itemToUpdate = item.name;
              const newValue = !getValueByName(item.name);

              const updatedData = settingsList.map(
                (item: SettingsDefault | SettingsResponse) => {
                  if (item.division === divisionSettings?.division) {
                    return {
                      ...item,
                      [itemToUpdate]: newValue,
                    };
                  }
                  return item;
                }
              );
              setSettingsList([...updatedData]);
            }}
          />
        );
      })}
    </FixaListView>
  );
};
