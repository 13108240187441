import React, { SVGProps } from "react";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import arrowPairSquarePath from "@ingka/ssr-icon/paths/arrow-pair-squarepath.js";
import priceTag from "@ingka/ssr-icon/paths/price-tag.js";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/focus/dist/style.css";

interface SVGElementProps extends SVGProps<SVGElement> {
  prefix?: string;
}
type SVGElementArray = (prefix?: string | undefined) => SVGElementProps[];

export enum SelectableIconPillIcons {
  ARROW_PAIR_SQUARE_PATH,
  PRICE_TAG,
}

interface SelectablePillProps {
  icon: SelectableIconPillIcons;
  label: string;
  selected: boolean;
  onClick: () => void;
}

function getSsrIcon(icon: SelectableIconPillIcons): SVGElementArray {
  switch (icon) {
    case SelectableIconPillIcons.ARROW_PAIR_SQUARE_PATH:
      return arrowPairSquarePath;
    case SelectableIconPillIcons.PRICE_TAG:
      return priceTag;
    default:
      return priceTag;
  }
}
const styles = {
  selected: {
    color: "#ffffff",
    backgroundColor: "#0058A3",
  },
  unselected: {
    color: "#111111",
    backgroundColor: "#DFDFDF",
  },
};

export function SelectableIconPill({
  icon,
  label,
  selected,
  onClick,
}: SelectablePillProps) {
  const ssrIcon = getSsrIcon(icon);
  return (
    <FixaPill
      ssrIcon={ssrIcon}
      label={label}
      selected={selected}
      onClick={onClick}
      size="xsmall"
      style={selected ? styles.selected : styles.unselected}
    />
  );
}
