import { useCallback } from "react";
import { useNavigate } from "react-router";

const useFixaNavigate = () => {
  const navigate = useNavigate();

  const fixaNavigate = useCallback(
    (to: string) => {
      navigate(to);
    },
    [navigate]
  );

  return fixaNavigate;
};

export default useFixaNavigate;
