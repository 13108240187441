import React from "react";
import styles from "./task-card.module.css";
import { useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { defaultTimezone } from "@coworker/app/src/constants/clientTime";
import { getDateActionFromState } from "../../helpers/getDateActionFromState";

interface TimestampProps {
  timestamp: number;
  type: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const Timestamp = ({ timestamp, type }: TimestampProps) => {
  const { t } = useTranslation();

  const dateAction = getDateActionFromState(type);

  const utcDate = dayjs.utc(timestamp).tz(defaultTimezone);
  const currentTime = dayjs();

  const timeDifferenceMinutes = currentTime.diff(utcDate, "minutes");
  const timeDifferenceHours = currentTime.diff(utcDate, "hours");
  const timeDifferenceDays = currentTime.diff(utcDate, "days");

  const getTimeString = (
    difference: number,
    singularKey: string,
    pluralKey: string
  ) =>
    `${difference} ${difference === 1 ? t(singularKey) : t(pluralKey)} ${t(
      "agoString"
    )}`;

  const date = () => {
    if (!timestamp) return "";
    if (timeDifferenceMinutes === 0) return t("justNowString");
    if (timeDifferenceMinutes < 60)
      return getTimeString(
        timeDifferenceMinutes,
        "minuteString",
        "minutesString"
      );
    if (timeDifferenceHours < 24)
      return getTimeString(timeDifferenceHours, "hourString", "hoursString");
    if (timeDifferenceDays <= 5)
      return getTimeString(timeDifferenceDays, "dayString", "daysString");

    return utcDate.format("YYYY-MM-DD");
  };

  return (
    <div className={styles["timestamp-wrapper"]}>
      <div className={styles["timestamp"]}>
        {t(dateAction)} {date()}
      </div>
    </div>
  );
};
