import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import imageIcon from "@ingka/ssr-icon/paths/image";
import commentIcon from "@ingka/ssr-icon/paths/speech-bubble";
import { useAllImages } from "@coworker/app/src/hooks/useTaskImages";
import { useTaskNotes } from "@coworker/app/src/hooks/useTaskNotes";
import { FixaWindow } from "@coworker/reusable";

export const ImagesAndComments = ({
  taskId,
  hasNotes,
  hasImages,
}: {
  taskId: string;
  hasNotes: boolean;
  hasImages: boolean;
}) => {
  const callTasksService = (window as FixaWindow).callTasksService;
  const images = useAllImages(taskId, !callTasksService);
  const notes = useTaskNotes(taskId, !callTasksService);
  return (
    <div className={styles["icon-wrapper"]}>
      {(images.length > 0 || hasImages) && (
        <SSRIcon paths={imageIcon} className={styles["icon"] as string} />
      )}
      {(notes.length > 0 || hasNotes) && (
        <SSRIcon paths={commentIcon} className={styles["icon"] as string} />
      )}
    </div>
  );
};
