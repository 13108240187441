import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import { Trans } from "@coworker/locales";
import * as React from "react";
import styled from "styled-components";
import { describeAreaFilter } from "../../InputPopup/UserFiltersRow/filterChoices";
import { ARTICLE_HASH } from "../constants";

const Container = styled.div`
  display: flex;
  padding: 0.8rem;
  border-bottom: 1px solid var(--grey100);
  font-weight: bold;
  color: black;
  gap: 8px;
`;
export interface StaticFilterRowProps {
  numberOfArticles?: number | undefined;
  area: string | undefined;
  typeOfArticles: string | undefined;
}

const getTypeOfArticlesLabel = (typeOfArticles: string = "") => {
  switch (typeOfArticles) {
    case ARTICLE_HASH.ACESANDKING?.type:
      return <Trans>fourAcesAndKingString</Trans>;
    case ARTICLE_HASH.CUSTOM?.type:
      return <Trans>{ARTICLE_HASH.CUSTOM?.transKey}</Trans>;
    case ARTICLE_HASH.RANDOM?.type:
      return <Trans>{ARTICLE_HASH.RANDOM?.transKey}</Trans>;
    default:
      return <></>;
  }
};
const getAreaLabel = (area: string = "") => {
  return area ? describeAreaFilter([area]) : "";
};

const getNumberOfArticlesLabel = (numberOfArticles: number = 0) => {
  return <Trans count={numberOfArticles}>countArticlesString</Trans>;
};

export default function StaticFilterRow({
  numberOfArticles,
  area,
  typeOfArticles,
}: StaticFilterRowProps) {
  return (
    //This div exist due to render issues on RDT
    <div>
      <Container data-testid="static-filter-row">
        <FixaPill
          size="xsmall"
          disabled={true}
          label={getTypeOfArticlesLabel(typeOfArticles)}
          aria-label={`${(
            <Trans>staticFilterRowAriaString</Trans>
          )} ${getTypeOfArticlesLabel(typeOfArticles)}`}
        />
        <FixaPill
          size="xsmall"
          disabled={true}
          label={getAreaLabel(area)}
          aria-label={`${(
            <Trans>staticFilterRowAriaString</Trans>
          )} ${getAreaLabel(typeOfArticles)} ${(<Trans>areaString</Trans>)}`}
        />
        {numberOfArticles && (
          <FixaPill
            size="xsmall"
            disabled={true}
            label={getNumberOfArticlesLabel(numberOfArticles)}
            aria-label={`${(
              <Trans>staticFilterRowAriaString</Trans>
            )} ${getNumberOfArticlesLabel(numberOfArticles)}`}
          />
        )}
      </Container>
    </div>
  );
}
