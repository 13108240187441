const stateTypeKeys = {
  CREATED: "createdString",
  COMPLETED: "completd3String",
  CLOSED: "closedFirstUppercaseString",
};

export function getDateActionFromState(type: string) {
  switch (type) {
    case "COMPLETED":
      return stateTypeKeys.COMPLETED;
    case "CLOSED":
      return stateTypeKeys.CLOSED;
    default:
      return stateTypeKeys.CREATED;
  }
}
