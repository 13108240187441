import React from "react";
interface ImageProps {
  image: string;
}

export const Image = ({ image }: ImageProps) => {
  if (!image) return null;

  return <img alt="productImage" height={69} src={image} />;
};
