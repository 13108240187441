import React, { useState } from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { Header } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { useParentPopup } from ".";
import {
  ArticleFilterTypes,
  ArticleSortingTypes,
} from "../RoomSettings/constants";
import { StickyButtonMenu } from "../RoomSettings/Molecules/StickyButtonMenu";
import {
  getArticleFilterTranslationString,
  isArticleFilterType,
  isArticleSortingType,
} from "../RoomSettings/tools";
import {
  InputPopupContainer,
  InputPopupContentBox,
  Title,
} from "../RoomSettings/styles/styles";
import { FixaWindow } from "@coworker/reusable";

type onSubmitData = {
  filter: string;
  sort: string;
  hfb: string;
};

interface RoomArticleFiltersProps {
  selectedFilter: string;
  selectedSort: string;
  selectedHFB: string;
  onSubmit: (data: onSubmitData) => void;
}

export const RoomArticleFilters = ({
  selectedFilter,
  selectedSort,
  selectedHFB,
  onSubmit,
}: RoomArticleFiltersProps) => {
  const { t } = useTranslation();
  const { getInput } = useParentPopup();
  const [localFilter, setLocalFilter] = useState(selectedFilter);
  const [localSort, setLocalSort] = useState(selectedSort);
  const [localHFB, setLocalHFB] = useState(selectedHFB);

  const showEDSdate = (window as FixaWindow).showEDSdate;

  const handleReset = () => {
    setLocalFilter(ArticleFilterTypes.ALL);
    setLocalSort(ArticleSortingTypes.PRIORITY);
    setLocalHFB("all");

    const data = {
      filter: ArticleFilterTypes.ALL,
      sort: ArticleSortingTypes.PRIORITY,
      hfb: "all",
    };

    onSubmit(data);
  };

  const handleRadioChange = (value: string) => {
    if (isArticleFilterType(value)) {
      setLocalFilter(value as ArticleFilterTypes);
    } else if (isArticleSortingType(value)) {
      setLocalSort(value as ArticleSortingTypes);
    }
  };

  const handleHFBChange = async () => {
    const response = await getInput("hfbs", {
      localHFB: localHFB,
    });
    if (response) {
      const hfb = response;
      setLocalHFB(hfb);
    }
  };

  const handleDone = () => {
    const data = {
      filter: localFilter,
      sort: localSort,
      hfb: localHFB,
    };

    onSubmit(data);
  };

  return (
    <>
      <Header title={t("filtersString")} onBack={handleDone} showBack border />

      <InputPopupContainer>
        <InputPopupContentBox>
          <Title>{t("typeOfArticlesString")}</Title>
          {Object.values(ArticleFilterTypes)
            .filter(
              (value) =>
                value !== ArticleFilterTypes.HFB &&
                (showEDSdate || value !== ArticleFilterTypes.OUTGOING_EDS)
            )
            .map((filter) => (
              <FixaListView id={filter} key={filter} size={"small"}>
                <FixaListViewItem
                  onChange={() => handleRadioChange(filter)}
                  title={t(getArticleFilterTranslationString(filter))}
                  control={"radiobutton"}
                  controlProps={{
                    value: filter,
                    checked: localFilter === filter,
                  }}
                />
              </FixaListView>
            ))}
          <FixaListView id={ArticleFilterTypes.HFB} size={"small"}>
            <FixaListViewItem
              onClick={handleHFBChange}
              title={
                localHFB === "all" ? t("wholeStoreString") : `HFB ${localHFB}`
              }
              control={"navigational"}
              controlIcon={"chevronRight"}
            />
          </FixaListView>
        </InputPopupContentBox>

        <InputPopupContentBox>
          <Title>{t("sortByTitleString")}</Title>
          {Object.values(ArticleSortingTypes)
            // Filter out latest activity sorting for now (will be added in later ticket)
            .filter((value) => value !== ArticleSortingTypes.LATEST_ACTIVITY)
            .map((sort) => (
              <FixaListView id={sort} key={sort} size={"small"}>
                <FixaListViewItem
                  onChange={() => handleRadioChange(sort)}
                  title={t(getArticleFilterTranslationString(sort))}
                  control={"radiobutton"}
                  controlProps={{
                    value: sort,
                    checked: localSort === sort,
                  }}
                />
              </FixaListView>
            ))}
        </InputPopupContentBox>

        <StickyButtonMenu onReset={handleReset} onDone={handleDone} />
      </InputPopupContainer>
    </>
  );
};
