import React from "react";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import { useCachedTasks } from "../services/tasks.service";

function sortTasksByLatestFinished(a, b) {
  return b.finished_at - a.finished_at;
}

/**
 * @returns {{ data:{ id: string | undefined }, loading: boolean }}
 */
export default function useLastAssignedTeam(productId) {
  const teams = useStoreTeams();
  // get last team for specific product that refilled it
  const result = useCachedTasks("all_completed", "", productId);

  // return the team id if the team exists (just in case if it was removed)
  return React.useMemo(() => {
    if (result?.loading) return { data: {} };
    const tasks = result?.list || [];
    const completedTasks = tasks
      .filter(
        (task) =>
          task.task_type === taskTypeOptions.ADDON &&
          task.product_article_id === productId
      )
      .sort(sortTasksByLatestFinished);
    const [latestTask] = completedTasks;
    const latestGroup = teams.find(
      ({ id }) => id === latestTask?.assigned_team_id
    );
    const data = latestGroup
      ? { id: latestTask?.assigned_team_id, ...latestGroup }
      : {};
    return { data };
  }, [result, teams, productId]);
}
