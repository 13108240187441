import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import Button from "@ingka/button";
import { useParams } from "react-router-dom";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import CompletedArticleList from "./CompletedArticleList";
import RemainingArticleList from "./RemainingArticleList";
import StaticFilterRow from "./StaticFilterRow";
import {
  callInternal,
  TESTBUY_SET_COMPLETED,
  TESTBUY_UPDATE_CUSTOM_NAME,
  TESTBUY_GET_ARTICLES_WITH_CRITERIA,
} from "../../../hooks/API/useCallInternal";
import { Trans, useTranslation } from "@coworker/locales";
import { useTestbuy as useTestbuyFS } from "../CommonComponents/Repositories/testbuy.repository";
import { useTestBuyArticles as useTestBuyArticlesFS } from "../CommonComponents/Repositories/article.repository.service";
import { useTestbuy as useTestbuyPG } from "../CommonComponents/Repositories/useTestbuyService";
import { useTestBuyArticles as useTestBuyArticlesPG } from "../CommonComponents/Repositories/useArticleService";
import { HistoricDetails } from "./HistoricDetails";
import { useTestbuyOptionsMenu as useTestbuyOptionsMenuFS } from "./useTestbuyOptionsMenu";
import { useTestbuyOptionsMenu as useTestbuyOptionsMenuPG } from "./useTestbuyOptionsMenuTestbuyService";
import { TestBuyProductWithCriteria } from "@coworker/types";
import { ReplaceArticlesGuide } from "./ReplaceArticlesGuide";
import { COMPLETED_STATUS } from "../constants";
import {
  postToTestbuyService,
  getFromTestbuyService,
} from "../../../hooks/API/testbuy.service";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon"; // NOSONAR
import EditIcon from "@ingka/ssr-icon/paths/pencil"; // NOSONAR
import CheckmarkIcon from "@ingka/ssr-icon/paths/checkmark"; // NOSONAR
import EllipsesHorizontalIcon from "@ingka/ssr-icon/paths/ellipses-horizontal"; // NOSONAR
import styled from "styled-components"; // NOSONAR
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";


type QueryParams = { id: string };

const setCaretPosition = (element: HTMLElement, col: number) => {
  const range = document.createRange();
  const selection = window.getSelection() as Selection;

  if (element.childNodes[0]) {
    range.setStart(element.childNodes[0], col);
  }

  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
  element.focus();
};

const CustomTitleInput = styled.p`
  border: none;
  background-color: transparent;
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const TestbuyCustomTitleEditInput = forwardRef(
  (
    {
      createdAt,
      customTitle,
    }: {
      createdAt: string;
      customTitle: string;
    },
    customTitleEditInputRef: React.Ref<HTMLInputElement>
  ) => {
    // There are two refs needed. One of them is just a regular ref (inputRef) to be used
    // in the restrictWhiteSpaceAndLength function. The other one is a ref that will be returned
    // to the parent component (customTitleEditInputRef). This is needed to focus the input
    // when the edit mode is activated.
    // The useImperativeHandle hook is used to return the inputRef to the parent component.
    // By default both, the inputRef and ref for the parent, can't be assigned to the same element.

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(customTitleEditInputRef, () => inputRef.current!);

    const restrictWhiteSpaceAndLength = (e: React.KeyboardEvent) => {
      if (!inputRef.current) return;

      const input = inputRef.current;
      if (
        (input.selectionStart === 0 && e.code === "Space") ||
        e.code === "Enter"
      ) {
        e.preventDefault();
      }

      if (input.innerText.length >= 18 && e.code !== "Backspace") {
        e.preventDefault();
      }
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.innerText = customTitle;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The editable element is not an input as the actual <input> element doesn't allow
    // to set content dependent width. Instead, a <p> element is used with the contentEditable attribute.
    return (
      <>
        <span>{`${createdAt} - `}</span>
        <CustomTitleInput
          contentEditable
          ref={inputRef}
          onKeyDown={restrictWhiteSpaceAndLength}
        >
          {decodeURIComponent(customTitle)}
        </CustomTitleInput>
      </>
    );
  }
);

export function TestBuyDetailPage() {
  const { t } = useTranslation();

  const { id: testbuyId } = useParams<QueryParams>();
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  const useTestbuy = avoidFirebase ? useTestbuyPG : useTestbuyFS;
  const useTestBuyArticles = avoidFirebase
    ? useTestBuyArticlesPG
    : useTestBuyArticlesFS;
  const useTestbuyOptionsMenu = avoidFirebase
    ? useTestbuyOptionsMenuPG
    : useTestbuyOptionsMenuFS;
  const { testbuy, loading: loadingTestbuy, refetch } = useTestbuy(testbuyId);
  const { articles, loading: loadingArticles } = useTestBuyArticles(testbuyId);
  const { getOptionsMenu, loading: popUpMenuProgress } = useTestbuyOptionsMenu({
    id: testbuyId,
  });

  const loading = loadingTestbuy || loadingArticles || popUpMenuProgress;
  const [articlesList, setArticlesList] = React.useState<
    TestBuyProductWithCriteria[]
  >([]);

  React.useEffect(() => {
    if (!loadingArticles) setArticlesList(articles);
  }, [loadingArticles, articles]);

  const createdAt = testbuy?.createdAt ?? "";

  const onChangeArticleFS = React.useCallback(async () => {
    const response = await callInternal(TESTBUY_GET_ARTICLES_WITH_CRITERIA, {
      testbuyKey: testbuyId,
    });
    setArticlesList(response.data);
  }, [testbuyId]);

  const onChangeArticlePG = React.useCallback(async () => {
    getFromTestbuyService(
      "article/" + testbuyId,
      (data: TestBuyProductWithCriteria[]) => {
        setArticlesList(data);
      }
    );
  }, [testbuyId]);

  const setTestbuyCompletedFS = React.useCallback(
    async (testbuyId) => {
      await callInternal(TESTBUY_SET_COMPLETED, { testbuyId });
      refetch();
    },
    [refetch]
  );

  const setTestbuyCompletedPG = React.useCallback(
    async (testbuyId) => {
      await postToTestbuyService(
        "testbuy/update_testbuy_completed",
        {
          id: testbuyId,
        },
        (data: string) => {
          if (data) refetch();
        }
      );
    },
    [refetch]
  );

  const onNameEditedPG = (newCustomName: string) => {
    postToTestbuyService(
      "testbuy/update_custom_name",
      {
        testbuyId,
        customName: newCustomName,
      },
      () => {}
    );
  };

  const onNameEditedFS = (newCustomName: string) => {
    callInternal(TESTBUY_UPDATE_CUSTOM_NAME, {
      testbuyKey: testbuyId,
      newCustomName,
    });
  };

  const isEditable = testbuy?.status === COMPLETED_STATUS;
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const customTitleEditInputRef = useRef<HTMLInputElement>(null);

  const [customTitle, setCustomTitle] = useState(() => {
    return testbuy?.customName;
  });

  const appBarActions: AppBarAction[] = [];

  if (isEditable && !isBeingEdited) {
    appBarActions.push({
      name: "Edit name",
      icon: <SSRIcon paths={EditIcon} />,
      onClick: () => {
        setIsBeingEdited(true);
        if (!customTitle) {
          setCustomTitle(testbuy?.customName);
        }
        setTimeout(() => {
          setCaretPosition(
            customTitleEditInputRef.current!,
            customTitle?.length || testbuy?.customName?.length
          );
        }, 0);
      },
      position: "right" as const,
    });
  } else if (!isEditable && !isBeingEdited) {
    appBarActions.push({
      name: "Options menu",
      icon: (
        <Button
          size="xsmall"
          iconOnly={true}
          type="tertiary"
          ssrIcon={EllipsesHorizontalIcon}
          aria-label={
            <Trans>{"testBuyDetailOptionsMenuAriaString"}</Trans> + "" //adding empty string to force the type to be string
          }
        />
      ),
      onClick: () => {
        getOptionsMenu();
      },
      position: "right" as const,
    });
  }

  if (isBeingEdited) {
    appBarActions.push({
      name: "Save",
      icon: <SSRIcon paths={CheckmarkIcon} />,
      onClick: () => {
        const customTitleEditText = customTitleEditInputRef.current?.innerText;
        if (avoidFirebase) {
          onNameEditedPG(customTitleEditText ?? "");
        } else {
          onNameEditedFS(customTitleEditText ?? "");
        }
        setCustomTitle(customTitleEditText ?? "");
        setIsBeingEdited(false);
      },
      position: "right" as const,
    });
  }

  const titleText =
    testbuy?.status === "IN_PROGRESS"
      ? `${createdAt} ${t("testBuyString")}`
      : createdAt;

  const customTitleText = () => {
    return !!testbuy?.customName
      ? ` - ${decodeURIComponent(customTitle || testbuy?.customName)}`
      : "";
  };

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("testBuysString"),
        to: "/testbuy",
      },
    ];
  }, [t]);

  return loading || !testbuyId ? (
    <FullScreenPopup
      appBarConfig={{
        title: titleText,
      }}
      noPadding
      linkbar={<Linkbar items={linkbarItems} currentLabel={createdAt} />}
    >
      <PositionedLoaderIcon />
    </FullScreenPopup>
  ) : (
    <FullScreenPopup
      appBarConfig={{
        title: isBeingEdited ? (
          <TestbuyCustomTitleEditInput
            ref={customTitleEditInputRef}
            createdAt={createdAt}
            customTitle={customTitle ?? ""}
          />
        ) : (
          titleText + customTitleText()
        ),
        actions: appBarActions,
      }}
      noPadding
      linkbar={<Linkbar items={linkbarItems} currentLabel={createdAt} />}
    >
      <StaticFilterRow
        typeOfArticles={testbuy?.typeOfArticles}
        area={testbuy?.area}
        numberOfArticles={testbuy?.numberOfArticles}
      />
      <ReplaceArticlesGuide />
      {testbuy?.status === COMPLETED_STATUS ? (
        <HistoricDetails testbuyId={testbuyId!} articles={articles} />
      ) : (
        <>
          <RemainingArticleList
            articles={articlesList}
            testbuyId={testbuyId!}
            onDone={
              avoidFirebase ? setTestbuyCompletedPG : setTestbuyCompletedFS
            }
            onChangeArticle={
              avoidFirebase ? onChangeArticlePG : onChangeArticleFS
            }
          />
          <CompletedArticleList
            articles={articlesList}
            testbuyId={testbuyId!}
            onChangeArticle={
              avoidFirebase ? onChangeArticlePG : onChangeArticleFS
            }
          />
        </>
      )}
    </FullScreenPopup>
  );
}
