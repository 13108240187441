import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import arrowIcon from "@ingka/ssr-icon/paths/arrow-pair-squarepath";
import useFormatScheduled from "@coworker/app/src/hooks/useFormatScheduled";
import { CustomTask, Planned } from "@coworker/types/lib/tasks/custom";
import { Task } from "@coworker/types/lib/tasks/base";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

export const Frequency = ({ task }: { task: Task }) => {
  const customTask = task as CustomTask;
  const { previewHint: frequency } = useFormatScheduled(
    (customTask.planned as Planned) ?? {}
  );

  const isTaskCompletedOrClosed =
    task.state === taskStateOptions.COMPLETED ||
    task.state === taskStateOptions.CLOSED;

  return (
    <>
      {frequency && (
        <div className={styles["frequency-container"]}>
          <SSRIcon
            className={`${styles["closed-icon"]} ${styles["frequency-icon"]} ${
              isTaskCompletedOrClosed && styles["frequency-completed-or-closed"]
            }`}
            paths={arrowIcon}
          />
          <div
            className={`${styles["frequency-text"]} ${
              isTaskCompletedOrClosed && styles["frequency-completed-or-closed"]
            }`}
          >
            {frequency}
          </div>
        </div>
      )}
    </>
  );
};
