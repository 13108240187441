import React, { useEffect, useState } from "react";
import { Room, Rooms } from "../types/room";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import styled from "styled-components";
import { RoomImagesListItem } from "../Molecules/RoomImagesListItem";
import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";
import Select, { Option } from "@ingka/select";
import Switch from "@ingka/switch";
import TextArea from "@ingka/text-area";
import tag from "@ingka/ssr-icon/paths/tag";
import filledStar from "@ingka/ssr-icon/paths/star-filled";
import star from "@ingka/ssr-icon/paths/star";
import paintbrush from "@ingka/ssr-icon/paths/paintbrush";
import family from "@ingka/ssr-icon/paths/family-gender-mixed";
import stairs from "@ingka/ssr-icon/paths/stairs";
import flag from "@ingka/ssr-icon/paths/flag";
import bike from "@ingka/ssr-icon/paths/bike";
import locationPinSmall from "@ingka/ssr-icon/paths/location-pin-small";
import { useTranslation } from "@coworker/locales";
import { getDepartmentsOptions } from "../../../helpers/departmentsOptions";
import { getStyleGroupOptions } from "../../../helpers/styleGroupOptions";
import { getLivingSituationOptions } from "../../../helpers/livingSituationOptions";
import { getLongTermPriorityOptions } from "../../../helpers/longTermPriorityOptions";
import { getPricingDataOptions } from "../../../helpers/pricingDataOptions";
import {
  checkEmptyRoomName,
  checkRoomName,
  getArticleName,
  getFullArticleName,
  validationMessage,
} from "./SettingsForm.helper";
import { LabelText } from "../Atoms/LabelText";
import { WarningTextWithIcon } from "../Atoms/WarningTextWithIcon";
import { Articles } from "../types/article";
import { Image } from "../types/images";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";

const PaddedHorizontalContainer = styled.div`
  padding: 0 1rem;
`;

const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface SettingsFormProps {
  room: Room;
  articles: Articles;
  onSave: (room: Room) => void;
  showUpdateFields?: boolean;
  images: Image[];
  onImageAddStart: (fileList: FileList) => void;
  onImageAddEnd: (images: Image[]) => void;
  onImageRemove: (images: Image[]) => void;
  onImageUpdate: (image: Image) => void;
}

export const SettingsForm = function ({
  room,
  articles,
  onSave,
  showUpdateFields = true,
  images,
  onImageAddStart,
  onImageAddEnd,
  onImageRemove,
  onImageUpdate,
}: SettingsFormProps) {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState<Rooms>([]);
  const { data } = useRoomsByStoreId(room.store_id);

  // Only load room-list once
  useEffect(() => {
    if (data) {
      setRooms(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaddedHorizontalContainer>
      <ModalItemWrapper>
        <FormField
          shouldValidate={
            !rooms || checkEmptyRoomName(room) || checkRoomName(room, rooms)
          }
          validation={{ msg: validationMessage(room, rooms ?? [], t) }}
          style={{ paddingRight: "12px" }}
        >
          <InputField
            id={"media_name"}
            type={"text"}
            label={<LabelText text={t("mediaNameString")} withAsterisk />}
            value={room.name}
            ssrIcon={tag}
            iconPosition={"leading"}
            onChange={(event) => {
              onSave({
                ...room,
                name: event.target.value,
              });
            }}
          />
        </FormField>

        <LabelText text={t("photosString")} />
        <RoomImagesListItem
          room={room}
          images={images}
          onAddStart={onImageAddStart}
          onAddEnd={onImageAddEnd}
          onRemove={onImageRemove}
          onUpdate={onImageUpdate}
        />

        {showUpdateFields && (
          <>
            <Select
              id={"main_article_id_1"}
              label={`${t("mainProductBasicString")} #1`}
              value={room.main_article_id_1}
              ssrIcon={filledStar}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...room,
                  main_article_id_1: articleId,
                  main_article_name_1: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      room.main_article_id_2,
                      room.backup_article_id_1,
                      room.backup_article_id_2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <Option
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </Select>
            <FlexSpaceBetween>
              <LabelText text={t("activeString")} />
              <Switch
                id="active"
                checked={room.active === "ACTIVE"}
                value={room.active || ""}
                onChange={() => {
                  onSave({
                    ...room,
                    active: room.active === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                  });
                }}
                disabled={!room.name}
              />
            </FlexSpaceBetween>
            {!room.name && (
              <WarningTextWithIcon text={t("activateRoomNameRequiredString")} />
            )}
            <Select
              id={"backup_article_id_1"}
              label={`${t("backupForProductString")} #1`}
              value={room.backup_article_id_1 ?? ""}
              ssrIcon={star}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...room,
                  backup_article_id_1: articleId,
                  backup_article_name_1: getArticleName(
                    articles ?? [],
                    articleId
                  ),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      room.main_article_id_1,
                      room.main_article_id_2,
                      room.backup_article_id_2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <Option
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </Select>
            <Select
              id={"main_article_id_2"}
              label={`${t("mainProductBasicString")} #2`}
              ssrIcon={filledStar}
              value={room.main_article_id_2}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...room,
                  main_article_id_2: articleId,
                  main_article_name_2: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      room.main_article_id_1,
                      room.backup_article_id_1,
                      room.backup_article_id_2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <Option
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </Select>
            <Select
              id={"backup_article_id_2"}
              label={`${t("backupForProductString")} #2`}
              value={room.backup_article_id_2 ?? ""}
              ssrIcon={star}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const articleId =
                  event.target.selectedIndex === 0 ? "" : event.target.value;
                onSave({
                  ...room,
                  backup_article_id_2: articleId,
                  backup_article_name_2: getArticleName(articles, articleId),
                });
              }}
            >
              {articles
                .filter(
                  (article) =>
                    ![
                      room.main_article_id_1,
                      room.backup_article_id_1,
                      room.main_article_id_2,
                    ].includes(article.id)
                )
                .map((article) => (
                  <Option
                    key={article.id}
                    value={article.id}
                    name={getFullArticleName(articles, article.id)}
                  />
                ))}
            </Select>
          </>
        )}
        <TextArea
          id={"media_story"}
          label={t("mediaStoryString")}
          style={{ height: "100px" }}
          value={room.media_story ?? ""}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onSave({
              ...room,
              media_story: event.target.value,
            });
          }}
        />
        <Select
          id={"style_group"}
          label={t("styleGroupString")}
          value={room.style_group}
          ssrIcon={paintbrush}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...room,
              style_group:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getStyleGroupOptions().map((styleGroup) => (
            <Option {...styleGroup} key={styleGroup.value} />
          ))}
        </Select>
        <Select
          id={"living_situation"}
          label={t("livingSituationString")}
          ssrIcon={family}
          value={room.living_situation ?? ""}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...room,
              living_situation:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getLivingSituationOptions().map((livingSituation) => (
            <Option {...livingSituation} key={livingSituation.value} />
          ))}
        </Select>
        <Select
          id={"hf_ltp"}
          label={t("longtermPriorityString")}
          value={room.hf_ltp}
          ssrIcon={flag}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...room,
              hf_ltp:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getLongTermPriorityOptions().map((hfltp) => (
            <Option {...hfltp} key={hfltp.value} />
          ))}
        </Select>
        <Select
          id={"pricing"}
          label={t("priceLevelString")}
          value={room.pricing}
          ssrIcon={stairs}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onSave({
              ...room,
              pricing:
                event.target.selectedIndex === 0 ? "" : event.target.value,
            });
          }}
        >
          {getPricingDataOptions().map((pricing) => (
            <Option {...pricing} key={pricing.value} />
          ))}
        </Select>
        <FormField style={{ paddingRight: "12px" }}>
          <InputField
            id={"life_at_home_activity"}
            type={"text"}
            label={t("lifeHomeActivityString")}
            value={room.laha ?? ""}
            ssrIcon={bike}
            iconPosition={"leading"}
            onChange={(event) => {
              onSave({
                ...room,
                laha: event.target.value,
              });
            }}
            style={{ paddingRight: "12px" }}
          />
        </FormField>
        <Select
          id={"hfb"}
          label={t("hfbLocationInStoreString")}
          value={room.hfb ?? ""}
          ssrIcon={locationPinSmall}
          onChange={(event) => {
            onSave({
              ...room,
              hfb: event.target.value,
            });
          }}
        >
          {getDepartmentsOptions(t).map((department) => (
            <Option {...department} key={department.value} />
          ))}
        </Select>
      </ModalItemWrapper>
    </PaddedHorizontalContainer>
  );
};
