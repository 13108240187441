import React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import filtersIcon from "@ingka/ssr-icon/paths/filters";
import { useTranslation } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import preferences from "@coworker/functions/src/enums/profilePreferences.json";
import { ArticleFilterTypes, ArticleSortingTypes } from "../constants";
import { getArticleFilterTranslationString } from "../tools";

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

interface ArticleFiltersProps {
  onArticlesChange: (filter: string, sort: string, hfb: string) => void;
}

export const ArticleFilters = ({ onArticlesChange }: ArticleFiltersProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const [selectedFilter, setSelectedFilter] = useUserPreference(
    preferences.RS_ARTICLE_FILTER,
    ArticleFilterTypes.ALL
  );
  const [selectedSort, setSelectedSort] = useUserPreference(
    preferences.RS_ARTICLE_SORT_FILTER,
    ArticleSortingTypes.PRIORITY
  );
  const [selectedHFB, setSelectedHFB] = useUserPreference(
    preferences.RS_ARTICLE_HFB_FILTER,
    "all"
  );

  const handleFilterClick = async () => {
    const response = await getInput("roomArticleFilters", {
      selectedFilter,
      selectedSort,
      selectedHFB,
    });
    if (response) {
      const { filter, sort, hfb } = response;
      setSelectedFilter(filter);
      setSelectedSort(sort);
      setSelectedHFB(hfb);

      onArticlesChange(filter, sort, hfb);
    }
  };

  return (
    <FiltersContainer>
      <Button
        type={"secondary"}
        text={t("filtersString")}
        ssrIcon={filtersIcon}
        onClick={handleFilterClick}
        size="small"
      />

      <FixaPill
        label={
          selectedHFB !== "all"
            ? `HFB ${selectedHFB}`
            : t(getArticleFilterTranslationString(selectedFilter))
        }
        size={"small"}
        onClick={handleFilterClick}
        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      />
      <FixaPill
        label={t(getArticleFilterTranslationString(selectedSort))}
        size={"small"}
        onClick={handleFilterClick}
        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      />
    </FiltersContainer>
  );
};
