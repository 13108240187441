import React, { useRef } from "react";
import { useUploadImageToCloud } from "../hooks/useUploadImageToCloud";
import { ImageUploadResponse } from "../types/images";
import Button from "@ingka/button";
import plus from "@ingka/ssr-icon/paths/plus";

interface UploadImageButtonProps {
  text?: string;
  onImageUploadStart: (fileList: FileList) => void | undefined;
  onImageUploadEnd: (fileList: ImageUploadResponse[]) => void;
}

export const UploadImageButton = ({
  text,
  onImageUploadStart,
  onImageUploadEnd,
}: UploadImageButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const uploadImageToCloud = useUploadImageToCloud();
  const fileList: ImageUploadResponse[] = [];

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    onImageUploadStart(event.target.files);

    for (const fileUploaded of event.target.files) {
      if (!fileUploaded) continue;

      const data = await uploadImageToCloud.mutateAsync(fileUploaded);
      if (!data) continue;

      fileList.push(data);
    }

    onImageUploadEnd(fileList);
  };

  const handleButtonClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      {text ? (
        <Button
          type={"primary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.isLoading}
          text={text ?? ""}
        />
      ) : (
        <Button
          type={"secondary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.isLoading}
          ssrIcon={plus}
          iconOnly
        />
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
};
