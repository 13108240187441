import React from "react";
import { useTranslation } from "@coworker/locales";
import styles from "./activity-filters-panel.module.css";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import { FixaListView } from "../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../shared/wrappers/FixaListView/FixaListViewItem";

type filterListEntry = {
  id: string;
  title: string;
  value: string;
  control: "radiobutton" | "checkbox";
  checked: boolean;
};

type filterList = filterListEntry[];

interface FilterListViewProps {
  title: string;
  list: filterList | filterList[];
  handleChange: (value: string) => void;
  open?: boolean;
  control?: "radiobutton" | "checkbox";
}

export const FilterListView = ({
  title,
  list,
  handleChange,
}: FilterListViewProps) => {
  const { t } = useTranslation();

  const getListViewItem = (item: filterListEntry) => {
    if (item.value === "") return null;

    return (
      <FixaListViewItem
        key={item.value}
        id={item.value}
        control={item.control}
        onChange={() => {
          handleChange(item.value);
        }}
        title={item.title}
        controlProps={{
          checked: item.checked,
          value: item.value,
        }}
      />
    );
  };

  return (
    <>
      {list.length > 0 && (
        <div
          className={styles[Array.isArray(list[0]) ? "no-padding" : "padding"]}
        >
          <FixaListView id={title}>
            <FixaListViewItem
              key="title"
              id="title"
              title={t(title)}
              emphasised
            />
            {list.map((item) => {
              if (Array.isArray(item)) {
                return (
                  <FixaAccordion padded>
                    {item.map((subItem) => {
                      return (
                        subItem.value !== "" && (
                          <FixaAccordionItem
                            id={subItem.title}
                            key={subItem.value}
                            title={title}
                          >
                            {getListViewItem(subItem)}
                          </FixaAccordionItem>
                        )
                      );
                    })}
                  </FixaAccordion>
                );
              } else {
                return getListViewItem(item);
              }
            })}
          </FixaListView>
        </div>
      )}
    </>
  );
};
