import React from "react";
import Status from "@ingka/status";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/status/dist/style.css";
import "./TasksListView.css";
import { TaskPreviewItem } from "../../types/taskPreviewType";
import { FixaListView } from "../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../shared/wrappers/FixaListView/FixaListViewItem";

interface TasksListViewProps {
  id: string;
  tasksList: TaskPreviewItem[];
  onClickTaskListItem: (taskId: string) => void;
}

export const TasksListView: React.FC<TasksListViewProps> = ({
  id,
  tasksList,
  onClickTaskListItem = () => null,
}) => {
  return (
    <FixaListView id={id} className="task-list-view">
      {tasksList.map((task) => {
        const taskIndicators = (
          <Status
            statusColor="red"
            small={true}
            label={task.indicatorText as string}
            className="border-none-indicator-dot"
          ></Status>
        );
        return (
          <FixaListViewItem
            key={task.id}
            id={task.id}
            control="navigational"
            onClick={() => {
              onClickTaskListItem(task.id);
            }}
            controlIcon={null}
            inset
            leadingIcon={task.icon}
            image={task.imageUrl ?? ""}
            title={task.title}
            description={task.description}
            size="medium"
            addOn={task.showIndicator ? taskIndicators : ""}
            emphasised={true}
          />
        );
      })}
    </FixaListView>
  );
};
