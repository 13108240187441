import React from "react";
import styles from "./task-card.module.css";

export const SubTitles = ({ subtitles }: { subtitles: string[] }) => {
  return (
    <>
      {subtitles.map((subtitle, index) => (
        <div className={styles["sub-title"]} key={index}>
          {subtitle}
        </div>
      ))}
    </>
  );
};
