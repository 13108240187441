import React, { useRef, useState, useMemo } from "react";
import { Trans } from "@coworker/locales";
import styles from "./task-list.module.css";
import { SwipeableTaskCard } from "../TaskCard/SwipeableTaskCard";
import { ProductHeader } from "@coworker/app/src/components/ProductHeader";
import { createTaskGroupList } from "@coworker/app/src/helpers/taskGrouping";
import { GroupedTasksHeader } from "../GroupedTasksHeader/GroupedTasksHeader";
import { LoadMore } from "../../../shared/components/LoadMore/LoadMore";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import FixaCheckbox from "../../../shared/wrappers/FixaCheckbox/FixaCheckbox";
import { TaskType } from "../../enums/taskTypes";
import { useItemsInfo } from "../../../shared/hooks/item/useItemsInfo";
import { Item } from "../../../shared/types/item";

interface TaskListProps {
  tasks: any[];
  scheduledTasks: any[];
  totalTasksCount: number;
  isMyTasks: boolean;
  multiSelect: boolean;
  type: string;
  groupBy: string;
  addonFilterOption: string[];
}

const ITEMS_PER_LOAD = 20;

const TaskList = ({
  tasks,
  scheduledTasks,
  totalTasksCount,
  isMyTasks,
  multiSelect,
  type,
  groupBy,
  addonFilterOption,
}: TaskListProps) => {
  const addonFilter = addonFilterOption?.[0];
  const groupedTasks: any[] = useMemo(
    () => createTaskGroupList(tasks, groupBy, addonFilter),
    [groupBy, tasks, addonFilter]
  );
  const { toggleAll, selectedTaskIds = [] } = useMultiSelectForTab(type) as {
    toggleAll?: (arr: string[]) => void | undefined;
    selectedTaskIds?: string[] | undefined;
    disabled: boolean;
  };

  const allTaskIds: string[] = tasks.map((task) => task.id);

  const allTasksAreSelected = useMemo(() => {
    const sortedAllTaskIds = [...allTaskIds].sort();
    const sortedSelectedTaskIds = [...selectedTaskIds].sort();
    if (sortedAllTaskIds.length !== sortedSelectedTaskIds.length) {
      return false;
    }
    for (let i = 0; i < sortedAllTaskIds.length; i++) {
      if (sortedAllTaskIds[i] !== sortedSelectedTaskIds[i]) {
        return false;
      }
    }
    return true;
  }, [allTaskIds, selectedTaskIds]);

  const [maxNbrOfVisibleItems, setMaxNbrOfVisibleItems] =
    useState(ITEMS_PER_LOAD);

  const tasksListRef = useRef<HTMLDivElement>(null);

  const filteredTasks = groupedTasks.filter(
    (item) => !item.isProductHeader && !item.isGroupHeader
  );
  const tasksLength = filteredTasks.length;

  const visibleTasks = groupedTasks.slice(
    0,
    Math.min(groupedTasks.length, maxNbrOfVisibleItems)
  );

  const itemNos = visibleTasks
    .map((task) => task.product_article_id)
    .filter(Boolean);
  const { data: itemsInfo } = useItemsInfo(itemNos);

  const handleLoadMoreTasksClick = () => {
    setMaxNbrOfVisibleItems((prev) => prev + ITEMS_PER_LOAD);
  };

  return (
    <div className={styles["task-list"]}>
      <div className={styles["counter"]}>
        {multiSelect ? (
          <Trans>selectedNoParenthesesString</Trans>
        ) : (
          <Trans>viewingString</Trans>
        )}
        {` ${
          multiSelect ? selectedTaskIds.length : tasksLength
        }/${totalTasksCount} `}
        <Trans>TasksString</Trans>
      </div>
      {multiSelect && (
        <div className={styles["select-all"]}>
          <FixaCheckbox
            id="select-all"
            value="select-all"
            label={<Trans>selectAllString</Trans>}
            indeterminate={
              selectedTaskIds.length > 0 &&
              selectedTaskIds.length !== allTaskIds.length
            }
            checked={allTasksAreSelected}
            onChange={() => toggleAll && toggleAll(allTaskIds)}
          />
        </div>
      )}
      <div className={styles["tasks"]} ref={tasksListRef}>
        {visibleTasks.map((task, i) => {
          if (task.isProductHeader) {
            return (
              <ProductHeader
                key={`related${task.fullId}`}
                fullId={task.fullId}
              />
            );
          } else if (task.isGroupHeader) {
            return (
              <GroupedTasksHeader
                key={i}
                selectedGroupByOption={task.groupByOption}
                numberOfTasksInGroup={task.numberOfTaskInGroup}
                groupByTitle={task.groupByTitle}
              />
            );
          } else {
            const scheduledTask =
              task.task_type === TaskType.CUSTOM &&
              scheduledTasks.find(
                (scheduledTask) => scheduledTask.id === task.repeatable_task_id
              );
            return (
              <SwipeableTaskCard
                key={task.id + task.group}
                task={
                  task.task_type === TaskType.CUSTOM
                    ? { ...task, planned: scheduledTask?.planned }
                    : task
                }
                item={
                  itemsInfo?.find(
                    (itemInfo: Item) => itemInfo.no === task.product_article_id
                  ) as Item
                }
                multiSelect={multiSelect}
                isMyTasks={isMyTasks}
                type={type}
              />
            );
          }
        })}
      </div>
      {maxNbrOfVisibleItems < tasksLength && (
        <LoadMore
          handleClick={handleLoadMoreTasksClick}
          loadedItemsCount={maxNbrOfVisibleItems}
          totalCount={tasksLength}
        />
      )}
    </div>
  );
};

export default TaskList;
