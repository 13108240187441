import React from "react";
import { LeftContainer, RightContainer, Row } from "../styles/styles";
import { Text } from "../Atoms/Text";
import { Trans, useTranslation } from "@coworker/locales";
import FixaCheckbox from "@coworker/apprestructured/src/shared/wrappers/FixaCheckbox/FixaCheckbox";
import { getArticlesCountText } from "../tools";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import styled from "styled-components";

const StyledFixaCheckbox = styled(FixaCheckbox)`
  display: flex;
  flex-direction: row;
  padding: 0px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 24px;
`;

interface SelectArticlesProps {
  selectedArticles: ArticleIdNbrArticles[];
  uniqueArticles: Articles;
  filteredArticles: Articles;
  isSelected: boolean;
  onSelectAll: (selectAllChecked: boolean) => void;
}

export function SelectArticles({
  selectedArticles,
  uniqueArticles,
  filteredArticles,
  isSelected,
  onSelectAll,
}: SelectArticlesProps) {
  const { t } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const articlesCountText = getArticlesCountText(
    t,
    selectedArticles,
    uniqueArticles,
    filteredArticles,
    isSelected
  );

  return (
    <ListItemContainer>
      <LeftContainer>
        <Text text={articlesCountText} />
      </LeftContainer>
      <RightContainer>
        {isSelected && (
          <Row>
            <Trans>selectAllString</Trans>
            &nbsp;&nbsp;
            <StyledFixaCheckbox
              id={"select-all-checkbox"}
              value="selectAll"
              checked={selectAllChecked}
              onChange={() => {
                const newSelectAllChecked = !selectAllChecked;
                setSelectAllChecked(newSelectAllChecked);
                onSelectAll(newSelectAllChecked);
              }}
            />
          </Row>
        )}
      </RightContainer>
    </ListItemContainer>
  );
}
