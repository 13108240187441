import React, { useState } from "react";
import { Room } from "../../types/room";
import { useRoomsByStoreId } from "../../hooks/useRoomsByStoreId";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import Button from "@ingka/button";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyRoomName,
  checkRoomName,
  EmptyRoom,
} from "../SettingsForm.helper";
import { MessageModal } from "./MessageModal";
import { OkCancelModal } from "./OkCancelModal";
import { SettingsForm } from "../SettingsForm";
import { Image } from "../../types/images";
import { useCreateRoom } from "../../hooks/useCreateRoom";
import { useAddImageToRoom } from "../../hooks/useAddImageToRoom";
import { useQueryClient } from "@tanstack/react-query";
import { updateImage } from "../../services/images.service";
import { QueryKeys } from "../../hooks/queryKeys";

interface CreateSettingsModalProps {
  storeId: string;
  isVisible: boolean;
  onClose: (roomId?: string) => void;
}

export const CreateSettingsModal = function ({
  storeId,
  isVisible,
  onClose,
}: CreateSettingsModalProps) {
  const { t } = useTranslation();
  const [showModalMessage, setShowModalMessage] = useState<string>("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [roomIsUpdated, setRoomIsUpdated] = useState(false);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [room, setRoom] = useState<Room>({ ...EmptyRoom, store_id: storeId });
  const [images, setImages] = useState<Image[]>([]);
  const { data: rooms } = useRoomsByStoreId(storeId);
  const createRoomMutation = useCreateRoom();
  const addImageToRoom = useAddImageToRoom(room.id);
  const queryClient = useQueryClient();

  const onSubmit = async () => {
    try {
      const roomId = await createRoomMutation.mutateAsync(room);
      for (const image of images) {
        image.roomId = roomId?.room_id ?? "";
        try {
          await addImageToRoom.mutateAsync(image);
        } catch (error) {
          alert(t("searchErrorString"));
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByRoom],
      });
      onClose(roomId?.room_id);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotCreateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (roomIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveRoomChanges = (saveRoom: Room) => {
    setRoomIsUpdated(true);
    setRoom(saveRoom);
  };

  const onImageAddStart = () => {
    setImagesAreUploading(true);
  };

  const onImageAddEnd = async (addImages: Image[]) => {
    setImages([...images, ...addImages]);
    setRoomIsUpdated(true);
    setImagesAreUploading(false);
  };

  const onImageRemove = async (removeImages: Image[]) => {
    for (const removeImage of removeImages) {
      const removeIndex = images.indexOf(removeImage);
      images.splice(removeIndex, 1);
    }
    setImages([...images]);
  };

  const onImageUpdate = async (image: Image) => {
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByRoom],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  if (!room) {
    return <></>;
  }

  const submitDisabled =
    !rooms ||
    checkEmptyRoomName(room) ||
    checkRoomName(room, rooms) ||
    !roomIsUpdated ||
    imagesAreUploading;

  return (
    <>
      <Modal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <Sheets
          labelledById="edit-room-setting"
          header={<ModalHeader title={t("createRoomSettingsString")} />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={submitDisabled}
                aria-label={t("create4String")}
              >
                {t("create4String")}
              </Button>
            </ModalFooter>
          }
        >
          <SettingsForm
            room={room}
            articles={[]}
            onSave={saveRoomChanges}
            showUpdateFields={false}
            images={images}
            onImageAddStart={onImageAddStart}
            onImageAddEnd={onImageAddEnd}
            onImageRemove={onImageRemove}
            onImageUpdate={onImageUpdate}
          />
        </Sheets>
      </Modal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            onClose();
            setRoom({ ...EmptyRoom, store_id: storeId });
            setImages([]);
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
    </>
  );
};
