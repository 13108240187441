import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from "@ingka/modal";
import styles from "./discover-now.module.css";
import { Trans, useTranslation } from "@coworker/locales";
import Button from "@ingka/button";
import Image from "@ingka/image";
import { UpptackaSetUpInfo } from "./UpptackaSetupInfo";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

interface DiscoverNowSheetProps {
  tileName: string;
  imageUrl: string;
  isSheetOpen: boolean;
  setIsSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DiscoverNowSheet = ({
  tileName,
  imageUrl,
  isSheetOpen,
  setIsSheetOpen,
}: DiscoverNowSheetProps) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  return (
    <Modal
      handleCloseBtn={() => setIsSheetOpen(!isSheetOpen)}
      visible={isSheetOpen}
      focusLockProps={{
        locked: false,
      }}
      keepContentMounted={true}
    >
      <Sheets
        alignment="right"
        footer={
          <ModalFooter className={styles["footer"] as string}>
            <Button
              type="primary"
              text={<Trans>createString</Trans>}
              onClick={() => push("/task/new/custom")}
            />
          </ModalFooter>
        }
        header={
          <ModalHeader
            ariaCloseTxt="Close button text"
            title={t("discoverNowString")}
          />
        }
      >
        <Image src={imageUrl} />
        <ModalBody>
          <div className={styles["sheet-title"]}>
            <Trans values={{ tile: tileName }}>tileNameInUpptackaString</Trans>
          </div>
          <div className={styles["set-up"]}>
            <Trans>upptackaSetUpString</Trans>
          </div>
          <UpptackaSetUpInfo />
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
