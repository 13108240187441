import React from "react";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { Trans } from "@coworker/locales";
import { ColorSchema, typesOfShoppingToolsList } from "../constants";
import { addShowroomToolsToMarkethall, findSettingsObject } from "../helper";
import { useFetchShoppingtoolsSettings } from "../Hooks/useFetchShoppingtoolsSettings";
import { SettingsDefault, SettingsResponse } from "../Types";
import { ToolsSkeleton } from "../Components/Skeletons/ToolsSkeleton";
import { ShoppingToolsListView } from "../Components/ShoppingToolsListView";

interface ToolsProps {
  id: string;
  title: string;
  division: string;
  setChosenTool: React.Dispatch<React.SetStateAction<string>>;
  chosenTool: string;
  colorSchema: ColorSchema;
}

export const Tools = ({
  id,
  title,
  division,
  setChosenTool,
  chosenTool,
  colorSchema,
}: ToolsProps) => {
  const { data: settingsList, isFetching } = useFetchShoppingtoolsSettings();

  if (isFetching) {
    return (
      <>
        <SubHeaderCard
          title={<Trans>{title}</Trans>}
          fontSize={16}
          colorSchema={colorSchema}
        />
        <ToolsSkeleton />
      </>
    );
  }

  if (!settingsList) return null;

  const activeSettings: SettingsDefault | SettingsResponse = findSettingsObject(
    settingsList,
    division
  )!;

  const markethallTools = addShowroomToolsToMarkethall(
    settingsList,
    activeSettings
  );

  const markethallSettings = typesOfShoppingToolsList("radiobutton").filter(
    (obj) => markethallTools.includes(obj.name)
  );

  return (
    <>
      <SubHeaderCard
        title={<Trans>{title}</Trans>}
        fontSize={16}
        colorSchema={colorSchema}
      />
      <ShoppingToolsListView
        id={id}
        itemList={markethallSettings}
        setChosenTool={setChosenTool}
        chosenTool={chosenTool}
        borderColor={colorSchema.background}
      />
    </>
  );
};
